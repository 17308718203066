<template>
  <b-modal
    id="modal-xl"
    :visible="isAddPermissionRuleActive"
    ok-only
    ok-title="Accept"
    centered
    size="xl"
    title="Выберите правило для заполнение роли"
    @change="(val) => $emit('update:is-add-permission-rule-active', val)"
  >
    <template #modal-footer="">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-modal.modal-primary
        variant="outline-info"
        @click="clearSelectTable()"
      >
        Очистить
      </b-button>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-modal.modal-primary
        variant="primary"
        @click="addRules"
      >
        Добавить
      </b-button>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-modal.modal-primary
        variant="outline-primary"
        @click="addRulesClose"
      >
        Добавить и закрыть
      </b-button>
    </template>

    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Показывать по</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>записей</label>
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <v-select
            v-model="subjectFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="subjectOptions"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="refPermissionRuleListTable"
      class="position-relative"
      :items="fetchItems"
      selectable
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="Совпадающих записей не найдено"
      :sort-desc.sync="isSortDirDesc"
      @row-selected="selectRowTable"
    >

      <template #cell(module)="data">
        {{ data.value }}
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Показано с {{ dataMeta.from }} по {{ dataMeta.to }} из {{ dataMeta.of }} элементов</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

  </b-modal>
</template>

<script>
import {
  BModal, VBModal, BRow, BCol, BTable, BButton, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import permissionRuleList from './permissionRuleList'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BTable,
    BButton,
    BPagination,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  model: {
    prop: 'isAddPermissionRuleActive',
    event: 'update:is-add-permission-rule-active',
  },
  props: {
    isAddPermissionRuleActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      tableColumns: [
        { key: 'subject', label: 'Модуль', sortable: true },
        { key: 'action', label: 'Действие' },
        { key: 'conditions', label: 'Ограничения', sortable: true },
        { key: 'type', label: 'Тип правила' },
        { key: 'title', label: 'Наименование', sortable: true },
      ],
    }
  },
  setup(props, { emit }) {
    const isAddNewPermissionRuleSidebarActive = ref(false)
    const {
      fetchItems,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPermissionRuleListTable,
      subjectOptions,
      actionOptions,

      resolveActionVariant,
      refetchData,
      updateTitle,
      deleteItem,
      clearSelectTable,
      selectRowTable,

      // Extra Filters
      subjectFilter,
      selectRules,
    } = permissionRuleList()

    const addRules = () => {
      emit('add-rules', selectRules.value)
      clearSelectTable()
    }

    const addRulesClose = () => {
      emit('add-rules', selectRules.value)
      emit('update:is-add-permission-rule-active', false)
    }

    return {
      isAddNewPermissionRuleSidebarActive,

      fetchItems,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPermissionRuleListTable,
      subjectOptions,
      actionOptions,

      resolveActionVariant,
      refetchData,
      updateTitle,
      deleteItem,
      clearSelectTable,
      selectRowTable,

      addRules,
      addRulesClose,

      // Extra Filters
      subjectFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
