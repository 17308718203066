import { ref, watch, computed } from '@vue/composition-api'
import permissionRepository from '@/repository/permissionRepository'
import router from '@/router'
import store from '@/store'

export default function permissionRoleRuleList() {
  const refPermissionRoleRuleListTable = ref(null)

  const perPage = ref(10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('subject')
  const isSortDirDesc = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refPermissionRoleRuleListTable.value ? refPermissionRoleRuleListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const refetchData = () => {
    refPermissionRoleRuleListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchItems = (ctx, callback) => {
    permissionRepository.roleRuleList({
      query: searchQuery.value,
      page: currentPage.value,
      limit: perPage.value,
      sord: isSortDirDesc.value ? 'desc' : 'asc',
      sidx: sortBy.value,
      roleId: router.currentRoute.params.id,
    })
      .then(response => {
        const { items, count } = response
        if ((items || []).length === 0) {
          callback([])
        }

        callback(items.map(item => {
          const {
            id, roleId, permissionId, role, rule,
          } = item
          let result = { id, roleId, permissionId }
          if (role) {
            result = { ...result, role: role.role, roleTitle: role.title }
          }
          if (rule) {
            const {
              action, subject, fields, conditions, type, title,
            } = rule
            result = {
              ...result, action, subject, fields, conditions, type, title,
            }
          }
          return result
        }))
        totalItems.value = count
      })
      .catch(error => {
        store.commit('app/SET_ERROR', error)
      })
  }

  const deleteItem = (id, callback) => {
    permissionRepository.deleteRuleFromRole(id)
      .then(() => {
        callback()
        refetchData()
      })
      .catch(error => {
        store.commit('app/SET_ERROR', error)
      })
  }

  const addRuleFromRole = (roleId, permissionIds) => new Promise((resolve, reject) => {
    const param = new FormData()
    param.append('roleId', roleId)
    param.append('permissionIds', JSON.stringify(permissionIds))
    permissionRepository.addRuleFromRole(param)
      .then(res => {
        refetchData()
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })

  return {
    fetchItems,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPermissionRoleRuleListTable,
    refetchData,
    deleteItem,
    addRuleFromRole,
  }
}
