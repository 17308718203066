<template>
  <div>
    <permission-rules-modal
      :is-add-permission-rule-active.sync="isAddPermissionRuleActive"
      @add-rules="addRules"
    />
    <b-row>
      <b-col cols="12">
        <b-card
          no-body
          class="mb-0"
        >
          <div class="m-2">
            <b-row>
              <b-col
                cols="12"
                md="4"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <h4>{{ roleData.title }} <span v-if="roleData && roleData.role"> ( {{ roleData.role }} )</span></h4>
              </b-col>
              <b-col
                cols="12"
                md="3"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Показывать по</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>записей</label>
              </b-col>

              <b-col
                cols="12"
                md="5"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    placeholder="Поиск..."
                  />
                  <b-button
                    variant="primary"
                    @click="isAddPermissionRuleActive = true"
                  >
                    <span class="text-nowrap">Добавить правило</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>

          </div>

          <b-table
            ref="refPermissionRoleRuleListTable"
            class="position-relative"
            :items="fetchItems"
            responsive
            :fields="fields"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="Совпадающих записей не найдено"
            :sort-desc.sync="isSortDirDesc"
          >

            <template #cell(module)="data">
              {{ data.value }}
            </template>

            <template #cell(actions)="data">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-primary"
                class="btn-icon rounded-circle"
                @click="deleteRule(data.item.id)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </template>

          </b-table>
          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">Показано с {{ dataMeta.from }} по {{ dataMeta.to }} из {{ dataMeta.of }} элементов</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalItems"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BTable, BFormInput, BButton, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import permissionRepository from '@/repository/permissionRepository'
import router from '@/router'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import permissionRoleRuleList from './components/permissionRoleRuleList'
import PermissionRulesModal from './components/PermissionRulesModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BFormInput,
    BButton,
    BPagination,
    vSelect,
    PermissionRulesModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        { key: 'subject', label: 'Модуль', sortable: true },
        { key: 'action', label: 'Действие' },
        { key: 'conditions', label: 'Ограничения', sortable: true },
        { key: 'type', label: 'Тип правила' },
        { key: 'title', label: 'Наименование', sortable: true },
        { key: 'actions', label: '', sortable: false },
      ],
    }
  },
  methods: {
    async deleteRule(id) {
      const result = await this.$swal({
        title: 'Подтверждение удаления!',
        text: 'Вы уверены в необходимости удалить правило?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Отмена',
        confirmButtonText: 'Удалить',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
      if (result.value) {
        this.deleteItem(id, () => {
          this.$swal({
            icon: 'success',
            title: 'Правило успешно удалено!',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-success',
            },
            buttonsStyling: false,
          })
        })
      }
    },
    async addRules(val) {
      try {
        await this.addRuleFromRole(this.roleData.id, val.map(x => x.id))
        this.$swal({
          icon: 'success',
          title: 'Правило успешно удалено!',
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: 'btn btn-success',
          },
          buttonsStyling: false,
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ошибка добавления правила',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error ? `${error}`.replace('Error: ', '') : '',
          },
        })
      }
    },
  },
  setup() {
    const isAddPermissionRuleActive = ref(false)
    const roleData = ref({})

    const {
      fetchItems,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPermissionRoleRuleListTable,
      refetchData,
      deleteItem,
      addRuleFromRole,

    } = permissionRoleRuleList()

    permissionRepository.getRole(router.currentRoute.params.id)
      .then(res => {
        roleData.value = res
      })

    return {
      isAddPermissionRuleActive,

      fetchItems,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPermissionRoleRuleListTable,
      refetchData,
      deleteItem,
      roleData,
      addRuleFromRole,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
